@keyframes ticker {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.ticker-track {
  animation: ticker linear infinite;
}

.ticker-track:hover {
  animation-play-state: paused;
}

@media (prefers-reduced-motion: no-preference) {
  .ticker-track {
    will-change: transform;
  }
}
